import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface ConnectAuthorizeRequest extends HttpRequestData {
}

export const connectAuthorize = httpUtil.createHttpRequestMeta<
    ConnectAuthorizeRequest,
    void
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/connect/authorize',
    method: 'POST',
    authentication: 'bearer'
});
