import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { CreateOrganizationRequest, createOrganization } from '@esg/business-account';
import {
    AppButton,
    Block,
    FlexBox,
    Header,
    InternalLink,
    Paragraph,
    Spacer,
    defaultShifts,
    globalModalState
} from '@esg/ui';
import { Layout } from 'antd';
import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { defaultTimezone } from '@esg/shared';
import { useTranslation } from 'react-i18next';
import { SetupConfirmModal } from './SetupConfirmModal';
import { translateCodes } from '@/locales';
import { setupPaths } from '@/pages/setup/@shared';
import { sampleCategoryData, sampleEmployeeData, sampleServiceData } from '@/pages/setup/@shared/dataSample';
import { useHttpCommand } from '@esg/framework';

const { Content, Footer } = Layout;

interface IStepsContent {
    title: string;
    desc: string;
    next?: string;
    back?: string;
}

const stepsContent: Record<string, IStepsContent> = {
    '0': {
        title: translateCodes.WELCOME_TO_ES,
        desc: translateCodes.SETUP_YOUR_SALON,
        next: setupPaths.branchSetup,
    },
    '1': {
        title: translateCodes.ADD_FIRST_BRANCH,
        desc: translateCodes.BRANCH_SETUP_DESC,
        back: setupPaths.info,
        next: setupPaths.businessCategory,
    },
    '2': {
        title: translateCodes.MAIN_BUSINESS_CATEGORY,
        desc: translateCodes.BUSINESS_CATEGORY_DESC,
        back: setupPaths.branchSetup,
        next: setupPaths.serviceSetup,
    },
    '3': {
        title: translateCodes.SERVICE_SETUP_TITLE,
        desc: translateCodes.SERVICE_SETUP_DESCRIPTION,
        back: setupPaths.businessCategory,
        next: setupPaths.employeeSetup,
    },
    '4': {
        title: translateCodes.EMPLOYEE_SETUP_TITLE,
        desc: translateCodes.EMPLOYEE_SETUP_DESCRIPTION,
        back: setupPaths.serviceSetup,
    },
};

interface SetupFormProps {
    readonly currentStep: string;
}

export const SetupForm = (props: React.PropsWithChildren<SetupFormProps>) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);

    const initDataSetup = JSON.parse(localStorage.getItem('initDataSetup') ?? '{}');

    const methods = useForm<CreateOrganizationRequest['body']>({
        defaultValues: {
            businessHour: defaultShifts,
            timezone: defaultTimezone,
            businessCategories: [],
            dateTimeFormat: 'MMM DD, YYYY',
            useSampleService: true,
            useSampleEmployee: true,
            serviceCategories: sampleCategoryData,
            services: sampleServiceData,
            employees: sampleEmployeeData,
            ...initDataSetup
        }
    });

    const createOrgCommand = useHttpCommand(createOrganization);
    const isLastStep = props.currentStep === '4';
    const currentStepContent = stepsContent[props.currentStep as string];

    const onSubmit = useCallback(
        () => methods.handleSubmit(async (data) => {
            if (!isLastStep) {
                if (!currentStepContent.next) {
                    return;
                }
                localStorage.setItem('initDataSetup', JSON.stringify(data));
                return void navigate(currentStepContent.next);
            }

            await createOrgCommand.mutateAsync({
                body: data
            },
                {
                    onSuccess() {
                        localStorage.removeItem('initDataSetup');
                    },
                });
            setGlobalModal({
                ...globalModal,
                isOpen: true,
                showModalFinish: true,
                footer: (
                    <AppButton
                        type='primary'
                        width='100%'
                        onClick={() => {
                            resetGlobalModal();
                            navigate('/users/authorize');
                        }}
                    >
                        {t(translateCodes.EXPLORE)}
                    </AppButton>
                ),
                content: <SetupConfirmModal />,
            });
        }),
        [createOrgCommand, currentStepContent.next, globalModal, isLastStep, methods, navigate, resetGlobalModal, setGlobalModal, t]
    );

    return (
        <Layout className='sub-layout'>
            <Content className='content'>
                <FlexBox justifyContent='center'>
                    <Block width={770}>
                        <Spacer top={80} />
                        <Header level={1} translateCode={currentStepContent.title} />
                        <Spacer top={12} bottom='lg'>
                            <Paragraph translateCode={currentStepContent.desc} />
                        </Spacer>
                        <FormProvider {...methods}>
                            {props.children}
                        </FormProvider>
                    </Block>
                </FlexBox>
            </Content>
            <Footer className='footer'>
                <InternalLink href={currentStepContent.back!}>
                    <AppButton
                        disabled={!currentStepContent.back}
                        translateCode={translateCodes.BACK}
                        icon={<LeftOutlined />}
                    />
                </InternalLink>
                <AppButton
                    loading={createOrgCommand.isPending}
                    onClick={onSubmit()}
                    type='primary'
                    translateCode={isLastStep ? translateCodes.FINISH : translateCodes.NEXT}
                    icon={<RightOutlined />}
                    iconPosition='end'
                />
            </Footer>
        </Layout>
    );
};
