import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetAllCountriesRequest extends HttpRequestData {
}

export interface GetAllCountriesResponse {
    readonly id: string;
    readonly name: string;
    readonly iso2: string;
    readonly currency: string;
    readonly phonecode: string;
}
export const getAllCountries = httpUtil.createHttpRequestMeta<GetAllCountriesRequest, GetAllCountriesResponse[]>({
    baseUrl: () => getEnv().COUNTRY_API_SERVER,
    path: '/v1/countries',
    method: 'GET',
});
