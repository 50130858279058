import {
    Block,
    Header,
    InternalLink,
    Paragraph,
    Spacer,
    Text,
} from '@esg/ui';
import { Alert, Divider } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { GroupSocialButton } from '../@shared';
import { authPaths } from '../@shared/authPaths';
import { RegisterForm } from './children/RegisterForm';

import { translateCodes } from '@/locales';
import { getEnv } from '@esg/shared';

export const Register = () => {
    const [searchParams] = useSearchParams();
    const queryValues = Object.fromEntries([...searchParams]);

    return (
        <Block>
            <Header
                level={1}
                translateCode={translateCodes.SIGN_UP_HEADER}
                textAlign='center'
            />
            <Spacer top='lg' bottom='md'>
                {
                    queryValues.error && (
                        <Spacer bottom='md'>
                            <Alert message={queryValues.error} type='error' />
                        </Spacer>
                    )
                }
                <RegisterForm />
            </Spacer>
            <Paragraph>
                <Text translateCode={translateCodes.BY_SIGNING_UP} />
                &nbsp;
                <InternalLink
                    href=''
                    translateCode={translateCodes.GENERAL_TERMS}
                />
                &nbsp;&&nbsp;
                <InternalLink
                    href=''
                    translateCode={translateCodes.PRIVACY_POLICY}
                />
            </Paragraph>
            <Spacer top='md' bottom='md'>
                <Divider plain style={{ margin: 0 }}>
                    or
                </Divider>
            </Spacer>
            <GroupSocialButton
                onGoogleClick={() => window.location.href = getEnv().GOOGLE_CHALLENGE_URL + '?action=register'}
            />
            <Spacer top='lg' />
            <Paragraph textAlign='center'>
                <Text translateCode={translateCodes.HAVE_AN_ACCOUNT_QUESTION} />
                &nbsp;
                <InternalLink
                    href={`${authPaths.login}${window.location.search}`}
                    translateCode={translateCodes.LOGIN_LINK}
                />
            </Paragraph>
        </Block>
    );
};
