import { HttpRequestData, httpUtil } from '@esg/framework';
import { Dayjs } from 'dayjs';

import { JOB_STATUS , getEnv } from '@esg/shared';

export interface IWorkingTimeItem {
    readonly isDayOff: boolean;
    readonly startTime: string | Dayjs;
    readonly endTime: string | Dayjs;
    readonly dayOfWeek: number;
    readonly dayOfWeekTitle: string;
    readonly breakTimes?: IBreakTimes[];
}

export interface IBreakTimes {
    readonly startTime: Dayjs;
    readonly endTime: Dayjs;
    readonly dayOfWeek: number;
    readonly dayOfWeekTitle: string;
    readonly isFixed?: boolean;
}

export type IWorkingTime = IWorkingTimeItem[];
export interface JobTypeInitCreate {
    tempId: number;
    name: string;
}
export interface EmployeeInitCreate {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone?: number;
    type?: 'sample' | 'added';
    tempJobTypeId?: number;
    jobStartDate?: Dayjs;
    jobStatus?: JOB_STATUS;
    avatar?: string;
}
export interface ServiceInitCreate {
    id: number;
    tempCategoryId: number;
    name: string;
    duration: number;
    price: number;
    description?: string;
    type: 'sample' | 'added';
}
export interface ServiceCategoryInitCreate {
    tempId: number;
    name: string;
    type: 'sample' | 'added';
}
export interface CreateOrganizationRequest extends HttpRequestData {
    body: {
        readonly name: string;
        readonly phoneNumber: string;
        readonly branchName: string;
        readonly address: string;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly postalCode: string;
        readonly timezone?: string;
        readonly businessHour: IWorkingTime;
        readonly dateTimeFormat?: string;
        readonly currency?: string;
        readonly businessCategories: number[];
        readonly serviceCategories: ServiceCategoryInitCreate[];
        readonly services: ServiceInitCreate[];
        readonly employees: EmployeeInitCreate[];
        readonly jobTypes: JobTypeInitCreate[];
    };
}

interface CreateOrganizationResponse {

}

export const createOrganization = httpUtil.createHttpRequestMeta<
    CreateOrganizationRequest,
    CreateOrganizationResponse
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/organizations',
    method: 'POST',
    authentication: 'bearer'
});
