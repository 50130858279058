import { ConnectTokenResponse, connectToken } from '@esg/business-account';
import {
    CheckboxField, InternalLink, Translate,
    AppButton,
    FlexBox,
    FormBase,
    InputField,
    RefMethodFormType
} from '@esg/ui';
import React, { useRef } from 'react';

import { useHttpCommand, viewRequests } from '@esg/framework';

import { useTranslation } from 'react-i18next';
import { authRules } from '../../@shared';
import { translateCodes } from '@/locales';
import { getEnv } from '@esg/shared';

interface LoginFormValues {
    readonly email: string;
    readonly password: string;
    readonly rememberMe: boolean;
}

interface LoginFormProps {
    readonly onSuccess: (response: ConnectTokenResponse) => void;
}

export const LoginForm = (props: LoginFormProps) => {
    const { t } = useTranslation();
    const formRef: RefMethodFormType = useRef(null);

    const loginCommand = useHttpCommand(connectToken, {
        onSuccess: props.onSuccess,
        showNotificationError: false
    });
    const onSubmit = React.useCallback(async (data: LoginFormValues) => {
        let scope = 'openid';
        if (data.rememberMe) {
            scope += ' offline_access';
        }
        loginCommand.mutate({
            contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
            body: {
                grant_type: 'password',
                username: data.email,
                password: data.password,
                client_id: getEnv().IDENTITY_CLIENT_ID,
                scope: scope,
            },
        }, {
            onError() {
                formRef.current?.setError('password', {
                    type: 'validate',
                    message: t(translateCodes.VAL_AU_01),
                });
            },
            onSuccess() {
                viewRequests.showNotification.send({
                    type: 'success',
                    title: t(translateCodes.NM_AU_01),
                    message: '',
                });
            },
        });
    },
        [loginCommand, t]);

    return (
        <FormBase onSubmit={onSubmit} formRef={formRef} defaultValues={{
            rememberMe: true
        }}>
            <FlexBox direction='column' gap={22}>
                <FlexBox direction='column'>
                    <InputField
                        name='email'
                        label={translateCodes.EMAIL}
                        placeholder={translateCodes.EMAIL_PLACEHOLDER}
                        rules={authRules.email}
                    />
                </FlexBox>
                <FlexBox direction='column'>
                    <InputField
                        type='password'
                        name='password'
                        label={translateCodes.PASSWORD}
                        placeholder={translateCodes.PASSWORD_PLACEHOLDER}
                        rules={authRules.passwordLogin}
                    />
                </FlexBox>

                <FlexBox justifyContent='space-between' alignItems='center'>
                    <CheckboxField name='rememberMe'>
                        <Translate
                            translateCode={
                                translateCodes.LOGIN_FORM_REMEMBER_ME
                            }
                        />
                    </CheckboxField>
                    <InternalLink
                        fontSize={14}
                        href='/auth/forgot-password'
                        fontWeight={600}
                        translateCode={translateCodes.FORGOT_PASSWORD}
                    />
                </FlexBox>
                <AppButton
                    loading={loginCommand.isPending}
                    htmlType='submit'
                    type='primary'
                    translateCode={translateCodes.LOGIN_LINK}
                />
            </FlexBox>
        </FormBase>
    );
};
