import { HttpRequestData, httpUtil } from '@esg/framework';

interface GetCoordinatesRequest extends HttpRequestData {
    readonly query: {
        address: string;
    };
}

export interface GetCoordinatesResponse {
    readonly status: string;
    readonly results: {
        geometry: {
            location: {
                lat: number;
                lng: number;
            };
        };
    }[];
}
export const getCoordinates = httpUtil.createHttpRequestMeta<GetCoordinatesRequest, GetCoordinatesResponse>({
    baseUrl: () => 'https://maps.googleapis.com/maps',
    path: '/api/geocode/json',
    method: 'GET',
});
