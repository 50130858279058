import { ArrowLeftOutlined } from '@ant-design/icons';
import { AppButton, FlexBox, urlUtil } from '@esg/ui';
import React from 'react';

import { getEnv } from '@esg/shared';
import { authTheme } from '../@shared';
import { AuthHeader } from '../@shared/components';
import { ForgotPasswordForm } from './children/ForgotPasswordForm';

import { translateCodes } from '@/locales';

export const ForgotPassword = () => {
    const redirectParams = {
        client_id: getEnv().MANAGEMENT_CLIENT_ID,
        redirect_uri: getEnv().MANAGEMENT_LOGIN_CALLBACK_PAGE
    };
    return (
        <FlexBox direction='column' gap={32}>
            <AuthHeader
                headTranslateCode={translateCodes.FORGOT_PASSWORD}
                descTranslateCode={translateCodes.SEND_RESET_INSTRUCTIONS}
                srcLogo={authTheme.icon.keyIcon}
            />
            <ForgotPasswordForm />
            <AppButton
                href={urlUtil.toRelativeUrl(getEnv().IDENTITY_LOGIN_PAGE, redirectParams)}
                type='text'
                icon={<ArrowLeftOutlined />}
                translateCode={translateCodes.BACK_TO_LOG_IN}
            />
        </FlexBox>
    );
};
