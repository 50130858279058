import React, { useState, ReactNode } from 'react';
import { Tabs } from 'antd';
import { Block } from '../layouts';

const { TabPane } = Tabs;

interface TabItem {
    key: string;
    render: React.ReactNode;
}

interface AppTabProps {
    items: TabItem[];
    children: (props: { activeKey: string }) => ReactNode;
    className?: string;
}

export const AppTab = ({ items, className, children }: AppTabProps) => {
    const [activeKey, setActiveKey] = useState<string>(items[0]?.key || '');

    return (
        <Block >
            <Tabs activeKey={activeKey} onChange={setActiveKey} className={className}>
                {items.map((item) => (
                    <TabPane tab={item.render} key={item.key} />
                ))}
            </Tabs>
            <Block>{children({ activeKey })}</Block>
        </Block>
    );
};
