export interface Env {
    GOOGLE_CLIENT_ID: string;
    GOOGLE_CHALLENGE_URL: string;

    IDENTITY_CLIENT_ID: string;
    IDENTITY_CLIENT_EXTERNAL_CALLBACK_URL: string;
    IDENTITY_SERVER: string;
    IDENTITY_CLIENT: string;
    IDENTITY_LOGIN_PAGE: string;
    MANAGEMENT_LOGIN_CALLBACK_PAGE: string;

    MANAGEMENT_SERVER: string;
    MANAGEMENT_SERVER_ID: string;
    MANAGEMENT_CLIENT: string;
    MANAGEMENT_CLIENT_ID: string;
    MANAGEMENT_REPORT_SERVER: string;

    MANAGEMENT_WEB_CLIENT_ID: string;

    BOOKING_CLIENT: string;

    COUNTRY_API_SERVER: string;
    COUNTRY_API_KEY: string;
    GOOGLE_MAPS_API_KEY: string;
    REALTIME_API_SERVER: string;

    LOGIN_PAG: string;
    LOGOUT_PAGE: string;
    LOGIN_CALLBACK: string;

    REPORT_SERVER: string;

    BOOKING_SERVER: string;
}

let _env: Env = null!;

export const getEnv = (): Env | never => {
    if (!_env) {
        throw new Error('env not set');
    }

    return _env;
};

export const setEnv = (newEnv: Partial<typeof _env>) => {
    if (_env) {
        console.warn('env already set');
    }

    _env = Object.assign(_env ?? {}, newEnv);
};