import { httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface ResetPasswordRequest {
    readonly body: {
        readonly userId: string;
        readonly token: string;
        readonly newPassword: string;
    };
}

export const resetPassword = httpUtil.createHttpRequestMeta<
    ResetPasswordRequest,
    void
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/account/reset-password',
    method: 'POST',
});
