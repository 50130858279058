import { HttpRequestData, httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface ConnectTokenRequest extends HttpRequestData {
}

export interface ConnectTokenResponse {
    readonly access_token: string;
    readonly expires_in: number;
    readonly token_type: string;
    readonly id_token?: string;
    readonly refresh_token?: string;
}

export const connectToken = httpUtil.createHttpRequestMeta<
    ConnectTokenRequest,
    ConnectTokenResponse
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/connect/token',
    method: 'POST',
});
