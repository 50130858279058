import { HttpRequestData, httpUtil } from '@esg/framework';

interface GetTimezoneByCoordinateRequest extends HttpRequestData {
}

export interface GetTimezoneByCoordinateResponse {
    dstOffset: number;
    rawOffset: number;
    status: string;
    timeZoneId: string;
    timeZoneName: string;

}
export const getTimezoneByCoordinate = httpUtil.createHttpRequestMeta<GetTimezoneByCoordinateRequest, GetTimezoneByCoordinateResponse>({
    baseUrl: () => 'https://maps.googleapis.com/maps',
    path: '/api/timezone/json',
    method: 'GET',
});