import { MutationOptions, useMutation } from '@tanstack/react-query';
import {
    HttpClient,
    HttpRequestError
} from '../http';
import { authActions } from '../requests/authRequest';
import { HttpRequestMeta } from '../types';
import { viewRequests } from '../requests';
import { useLoginState } from './useLoginState';

interface HttpCommandOptions<TRequest, TResponse> extends MutationOptions<TResponse, Error, TRequest> {
    readonly showNotificationError?: boolean;
}

export const useHttpCommand = <TRequest, TResponse>(requestMeta: HttpRequestMeta<TRequest, TResponse>, options?: HttpCommandOptions<TRequest, TResponse>) => {
    const [loginInfo] = useLoginState();
    const showNotificationError = options?.showNotificationError ?? true;

    const baseUrl = requestMeta.baseUrl();

    if (!baseUrl) {
        throw new Error('Base URL is null');
    }

    const query = useMutation<TResponse, Error, TRequest>({
        mutationKey: [requestMeta.method, baseUrl, requestMeta.path],
        mutationFn: async <TRequest>(requestData: TRequest) => {
            const httpClient = new HttpClient();

            if (requestMeta.authentication === 'bearer') {
                const accessToken = loginInfo?.token;
                if (!accessToken) {
                    throw new Error('Access token is null');
                }

                httpClient.setAuthorization('bearer', accessToken);
            }

            try {
                return await httpClient.send<TResponse>({
                    ...requestMeta,
                    ...requestData,
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'HttpRequestError') {
                    const requestError = error as HttpRequestError;

                    if (requestError.statusCode === 401) {
                        authActions.logout.send();
                        if (window.location) {
                            window.location.href = '/error/unauthorized';
                        }
                    }
                }

                if (showNotificationError) {
                    viewRequests.showNotification.send({
                        type: 'error',
                        title: (error as Error).name,
                        message: (error as Error).message,
                    });
                }

                throw error;
            }
        },
        onSuccess: options?.onSuccess,
        onError: options?.onError,
    });

    return query;
};
