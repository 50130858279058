import React from 'react';
import { message, Upload, Spin } from 'antd';
import { UploadProps } from 'antd/lib';
import { FileUpload } from '@esg/business-upload';
import { useLoginState } from '@esg/framework';
import { getEnv } from '@esg/shared';
import { FlexBox } from '../layouts';
import { AppButton } from '../buttons';
import { IconUpload } from '../icons';
import { Text } from '../typo';

const { Dragger } = Upload;

interface DraggerUploadProps {
    readonly name?: string;
    readonly disabled?: boolean;
    readonly onChange?: (value: FileUpload) => void;
}

export const DraggerUpload = (props: DraggerUploadProps) => {
    const [login] = useLoginState();
    const { name, onChange } = props;
    const [loading, setLoading] = React.useState(false);

    const endpoint = '/api/v1/file-uploads';
    const action = getEnv().MANAGEMENT_SERVER + endpoint;
    const token = login?.token;

    const uploadProps: UploadProps = {
        name: name,
        disabled: props.disabled,
        multiple: false,
        showUploadList: false,
        action: action,
        headers: { Authorization: `Bearer ${token}` },
        onChange(info) {
            const { status } = info.file;
            if (status === 'uploading') {
                setLoading(true);
            }
            if (status === 'done') {
                setLoading(false);
                onChange?.(info.file.response.items[0]);
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                setLoading(false);
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <Dragger {...uploadProps}>
            <Spin spinning={loading}>
                <FlexBox direction='column' justifyContent='center' alignItems='center' gap={4} height={168}>
                    <AppButton icon={<IconUpload />} />
                    <Text fontWeight={300}>
                        <Text color='#3685FB' fontWeight={600}>Click to upload</Text> or drag and drop
                    </Text>
                    <Text fontWeight={300}>SVG, PNG, JPG or GIF (max. 1600x800 px)</Text>
                </FlexBox>
            </Spin>
        </Dragger>
    );
};
