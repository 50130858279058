import { httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface VerifyEmailRequest {
    readonly body: {
        readonly userId: string;
        readonly createConfirmEmailToken: string;
        readonly password?: string;
    };
}

export const verifyEmail = httpUtil.createHttpRequestMeta<
    VerifyEmailRequest,
    void
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/account/verify-email',
    method: 'POST',
});
