import { ConnectTokenResponse, connectToken } from '@esg/business-account';
import {
    Block,
    FlexBox,
    Header,
    InternalLink,
    Spacer,
    Text,
    useHttpCommand,
} from '@esg/ui';
import { Alert, Divider } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { convertOpenIdResponse } from '@esg/auth';
import { useLoginStore } from '@esg/ui/hooks/useExistingLogin';
import { useLoginState } from '@esg/framework';
import { GroupSocialButton, authPaths } from '../@shared';
import { LoginForm } from './children';

import { paths } from '@/configs';
import { translateCodes } from '@/locales';
import { getEnv } from '@esg/shared';

export const Login = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const queryValues = Object.fromEntries([...searchParams]);
    const [, setLoginState] = useLoginState();

    const { setData: saveLogin } = useLoginStore();

    const onLoginSuccess = React.useCallback((response: ConnectTokenResponse) => {
        const loginStateValue = convertOpenIdResponse(response);
        setLoginState(loginStateValue);
        saveLogin(loginStateValue);
        navigate(paths.authorize);
    }, [navigate, saveLogin, setLoginState]);

    const { mutate: doCodeLogin } = useHttpCommand(connectToken, { onSuccess: onLoginSuccess });

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        const redirect_uri = searchParams.get('redirect_uri');
        const client_id = searchParams.get('client_id');
        const scope = searchParams.get('scope');

        if (redirect_uri) {
            sessionStorage.setItem('redirect_uri', redirect_uri || '');
        }

        if (client_id) {
            sessionStorage.setItem('client_id', client_id || '');
        }

        if (scope) {
            sessionStorage.setItem('scope', scope || '');
        }
    }, []);

    useEffect(() => {
        if (queryValues?.code) {
            doCodeLogin({
                contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
                body: {
                    grant_type: 'authorization_code',
                    code: queryValues.code,
                    client_id: getEnv().IDENTITY_CLIENT_ID,
                    redirect_uri: getEnv().IDENTITY_CLIENT_EXTERNAL_CALLBACK_URL
                }
            });
        }

        return () => {

        };
    }, [doCodeLogin, queryValues?.code]);

    return (
        <Block>
            <Header
                level={1}
                fontSize={30}
                textAlign='center'
                translateCode={translateCodes.LOGIN_HEADER}
            />
            <Spacer bottom='lg' />
            <FlexBox direction='column' gap={20}>
                {
                    queryValues?.error && (
                        <Alert message={queryValues.error} type='error' />
                    )
                }
                {
                    queryValues?.code && (
                        <Alert
                            message={(
                                <div>
                                    Login successfully, redirecting...
                                </div>
                            )}
                            type='success'
                        />
                    )
                }
                <LoginForm onSuccess={onLoginSuccess} />
                <Divider plain style={{ margin: 0 }}>
                    or
                </Divider>
                <GroupSocialButton
                    onGoogleClick={() => window.location.href = getEnv().GOOGLE_CHALLENGE_URL + '?action=login'}
                />
                <Block textAlign='center'>
                    <Text
                        translateCode={translateCodes.DO_NOT_HAVE_ACCOUNT}
                        fontSize={14}
                    />
                    &nbsp;
                    <InternalLink
                        fontSize={14}
                        href={`${authPaths.register}`}
                        translateCode={translateCodes.SIGN_UP_LINK}
                    />
                </Block>
            </FlexBox>
        </Block>
    );
};
