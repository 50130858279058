import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetCitiesByCountryAndStateRequest extends HttpRequestData {
    readonly pathData: {
        country: string;
        state: string;
    };
}

export interface GetCitiesByCountryAndStateResponse {
    readonly id: string;
    name: string;
}
export const getCitiesByCountryAndState = httpUtil.createHttpRequestMeta<GetCitiesByCountryAndStateRequest, GetCitiesByCountryAndStateResponse[]>({
    baseUrl: () => getEnv().COUNTRY_API_SERVER,
    path: '/v1/countries/:country/states/:state/cities',
    method: 'GET',
});
