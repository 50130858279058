import { httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface ResetPasswordRequest {
    readonly body: {
        email: string;
    };
}

export const resentEmailVerify = httpUtil.createHttpRequestMeta<
    ResetPasswordRequest,
    void
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/account/resent-email-verification',
    method: 'POST',
});
