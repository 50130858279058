import { useMemo } from 'react';
import { getAllCountries, getCitiesByCountryAndState, getStateByCountry } from '@esg/business-setting';
import currency from '@esg/shared/json/currency.json';
import { getEnv } from '@esg/shared';
import { useHttpQuery } from '.';

export interface LocationOption {
    readonly value: string;
    readonly label: string;
    readonly [key: string]: unknown;
}

export const useLocationOptions = (currentCountry?: string, currentStates?: string) => {
    const allTimezones = Intl.supportedValuesOf('timeZone');

    const { data: allCountries } = useHttpQuery(getAllCountries, {
        header: { 'X-CSCAPI-KEY': getEnv().COUNTRY_API_KEY }
    });

    const { data: dataStates } = useHttpQuery(getStateByCountry, {
        header: { 'X-CSCAPI-KEY': getEnv().COUNTRY_API_KEY },
        pathData: {
            country: currentCountry,
        },
    }, { enabled: !!currentCountry });

    const { data: dataCities } = useHttpQuery(getCitiesByCountryAndState, {
        header: { 'X-CSCAPI-KEY': getEnv().COUNTRY_API_KEY },
        pathData: {
            country: currentCountry,
            state: currentStates,
        },
    }, { enabled: !!currentStates });

    const countriesOption: LocationOption[] = useMemo(() => allCountries?.map((o) => ({
        value: o.iso2,
        label: o.name,
        currency: o.currency
    })) ?? [], [allCountries]);

    const phoneCodeOptions: LocationOption[] = useMemo(() => allCountries?.map((o) => ({
        value: o.phonecode,
        label: `${o.phonecode} - ${o.iso2}`
    })) ?? [], [allCountries]);

    const statesOption: LocationOption[] = useMemo(() => dataStates?.map((o) => ({
        value: o.iso2,
        label: o.name
    })) ?? [], [dataStates]);

    const citiesOption: LocationOption[] = useMemo(() => dataCities?.map((o) => ({
        value: o.name,
        label: o.name
    })) ?? [], [dataCities]);

    const timezoneOption: LocationOption[] = useMemo(() => allTimezones?.map((tz: string) => ({
        value: tz,
        label: tz
    })) ?? [], [allTimezones]);

    const currencyOptions: LocationOption[] = useMemo(() => currency.map((c) => ({
        value: c.code,
        label: `${c.code} - ${c.name}`,
    })) ?? [], []);

    return { countriesOption, statesOption, citiesOption, timezoneOption, phoneCodeOptions, currencyOptions };
};
