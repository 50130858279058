import { HttpRequestData, httpUtil } from '@esg/framework';
import { getEnv } from '@esg/shared';

interface GetStateByCountryRequest extends HttpRequestData {
    readonly pathData: {
        country: string;
    };
}

export interface GetStateByCountryResponse {
    readonly id: string;
    name: string;
    iso2: string;
}
export const getStateByCountry = httpUtil.createHttpRequestMeta<GetStateByCountryRequest, GetStateByCountryResponse[]>({
    baseUrl: () => getEnv().COUNTRY_API_SERVER,
    path: '/v1/countries/:country/states',
    method: 'GET',
});
