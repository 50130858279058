import { ArrowLeftOutlined } from '@ant-design/icons';
import { AppButton, urlUtil } from '@esg/ui';
import { Flex } from 'antd';
import React from 'react';

import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import { getEnv } from '@esg/shared';
import { authTheme } from '../@shared';
import { AuthHeader } from '../@shared/components';

import { translateCodes } from '@/locales';

export const ResetPasswordSuccess = () => {
    const { t } = useTranslation();
    const ShowAlert = () => {
        return viewRequests.showNotification.send({
            type: 'success',
            title: t(translateCodes.RESET_PASSWORD_SUCCESS),
            message: t(translateCodes.RESET_PASSWORD_SUCCESS),
        });
    };
    const redirectParams = {
        client_id: getEnv().MANAGEMENT_CLIENT_ID,
        redirect_uri: getEnv().MANAGEMENT_LOGIN_CALLBACK_PAGE
    };
    return (
        <Flex vertical gap={32} align='center'>
            <AuthHeader
                headTranslateCode={translateCodes.PASSWORD_RESET}
                descTranslateCode={translateCodes.CHANGE_PASSWORD_SUCCESS}
                srcLogo={authTheme.icon.checkIcon}
            />
            <AppButton href={urlUtil.toRelativeUrl(getEnv().IDENTITY_LOGIN_PAGE, redirectParams)} onClick={ShowAlert} type='primary' width='100%'>
                Continue
            </AppButton>
            <AppButton
                href={urlUtil.toRelativeUrl(getEnv().IDENTITY_LOGIN_PAGE, redirectParams)}
                type='text'
                icon={<ArrowLeftOutlined />}
                translateCode={translateCodes.BACK_TO_LOG_IN}
            />
        </Flex>
    );
};
