import { httpUtil } from '@esg/framework';

import { getEnv } from '@esg/shared';

interface ForgotPasswordRequest {
    readonly body: {
        readonly email: string;
    };
}

export const forgotPassword = httpUtil.createHttpRequestMeta<
    ForgotPasswordRequest,
    void
>({
    baseUrl: () => getEnv().IDENTITY_SERVER,
    path: '/api/v1/account/send-reset-password-email',
    method: 'POST',
});
