import { connectAuthorize, UserOrganizations } from '@esg/business-account';
import { Block, useHttpCommand } from '@esg/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { setupPaths } from '@/pages/setup/@shared';

interface SelectOrganizationProps {
    readonly userOrganizations: UserOrganizations[];
    readonly redirectUri: string;
    readonly clientId: string;
    readonly scope: string;
}

export const SelectOrganization = (props: SelectOrganizationProps) => {
    const navigate = useNavigate();
    const { redirectUri, clientId, scope } = props;

    const { mutateAsync: execAuthorize } = useHttpCommand(connectAuthorize);

    const authorize = React.useCallback(async (scope: string) => {
        await execAuthorize({
            contentType: 'application/x-www-form-urlencoded;charset=UTF-8',
            body: {
                redirect_uri: decodeURIComponent(redirectUri),
                client_id: clientId,
                response_type: 'code',
                scope: scope,
            },
        });
    }, [clientId, execAuthorize, redirectUri]);

    React.useEffect(() => {
        console.log(props);
        if (!props.userOrganizations.length) {
            return void navigate(setupPaths.info);
        }

        if (props.userOrganizations.length === 1) {
            return void authorize(`${scope} organization:${props.userOrganizations[0].organizationId}`);
        }
    }, [authorize, navigate, props.userOrganizations, scope]);

    if (!props.userOrganizations.length || props.userOrganizations.length === 1) {
        return null;
    }

    return (
        <Block>
            <div>SELECT_ORGANIZATION</div>
            <div>
                {props.userOrganizations.map((userOrganization) => (
                    <div
                        key={userOrganization.organizationId}
                        onClick={() => {
                            authorize(`${scope} organization:${userOrganization.organizationId}`);
                        }}
                    >
                        {userOrganization.organization.name}
                    </div>
                ))}
            </div>
        </Block>
    );
};