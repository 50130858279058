import { ArrowLeftOutlined } from '@ant-design/icons';
import { AppButton, urlUtil } from '@esg/ui';
import { Flex } from 'antd';
import React from 'react';

import { getEnv } from '@esg/shared';
import { authTheme } from '../@shared';
import { AuthHeader } from '../@shared/components';
import { ResetPasswordForm } from './children/ResetPasswordForm';

import { translateCodes } from '@/locales';

export const ResetPassword = () => {
    const redirectParams = {
        client_id: getEnv().MANAGEMENT_CLIENT_ID,
        redirect_uri: getEnv().MANAGEMENT_LOGIN_CALLBACK_PAGE
    };

    return (
        <Flex vertical gap={32}>
            <AuthHeader
                headTranslateCode={translateCodes.SET_NEW_PASSWORD}
                descTranslateCode={translateCodes.NEW_PW_MUST_DIFF_OLD_PW}
                srcLogo={authTheme.icon.unlockIcon}
            />
            <ResetPasswordForm />
            <AppButton
                href={urlUtil.toRelativeUrl(getEnv().IDENTITY_LOGIN_PAGE, redirectParams)}
                type='text'
                icon={<ArrowLeftOutlined />}
                translateCode={translateCodes.BACK_TO_LOG_IN}
            />
        </Flex>
    );
};
